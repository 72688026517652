/**
 * External dependencies
 */

import { createElement } from 'react';
import { addFilter } from '@wordpress/hooks';

/**
 * Internal dependencies
 */

import styles from './AdminPageAP.module.scss';

const AdminPageAPComponent = () => {
	return (
		<div className={styles.dashboard}>
			<div className={styles.content}>
				<img
					src="/wp-content/plugins/veem-payments/assets/images/logo-veem.svg"
					alt="Veem Logo"
				/>

				<h2 className={styles.message}>
					Pay your vendors and suppliers with Veem{' '}
					<p>Save on all your domestic and international payments</p>
				</h2>
				<div className={styles.actions}>
					<a
						className={[
							styles['button-primary'],
							styles.button,
							styles['button-cta'],
						].join(' ')}
						href="https://apps.veem.com/CustomerApp/o/signup?utm_source=woocommerce&utm_medium=partner"
						target="_blank"
						rel="noreferrer"
					>
						Pay with Veem
					</a>
					<a
						className={styles.button}
						target="_blank"
						href="https://veem.com?utm_source=woocommerce&utm_medium=partner"
						rel="noreferrer"
					>
						Learn more
					</a>
				</div>
			</div>
		</div>
	);
};

export const AdminPageAP = () =>
	addFilter('woocommerce_admin_pages_list', 'veem-payments', (pages) => {
		pages.push({
			container: AdminPageAPComponent,
			path: '/veem-payments',
			breadcrumbs: ['Veem Payments'],
			navArgs: {
				id: 'veem-payments',
			},
		});

		return pages;
	});
